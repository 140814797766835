<template>


  <transition name="slide">

  <div class="view-trip">
   <main-nav />
    <main-titlebar myclass="redBar" showback="true"/>
    <div class="trip-view-wrapper">
         <div v-for="trip in trips" :key="trip.id" class="trip-view-row2">

          <div v-if="trip.client > 0" class="trip-view-row">
            <div class="trip-view-col textAlignCenter"> <b> {{ trip.client_name }}</b></div>
          </div>


          <div class="trip-view-row">
            <div class="trip-view-col"><i class="fa-solid fa-clock"></i>  {{ statusFriendly(trip.status) }} ({{trip.status}})</div>
          </div>


          <div class="trip-view-row">
            <div class="trip-view-col"><i class="fa-solid fa-clock"></i>  {{ formatDateTime(trip.etd) }}</div>
          </div>
          <div class="trip-view-row">
            <div class="trip-view-col"><i class="fa-solid fa-crosshairs"></i> {{trip.origin_address}}</div>
          </div>
          <div class="trip-view-row">
            <div class="trip-view-col"><i class="fa-solid fa-crosshairs"></i> {{trip.destination_address}}</div>
          </div>
        
          <div class="trip-view-row">
            <div class="trip-view-col2"><i class="fa-solid fa-car"></i> {{trip.driver_name}}</div>
            <div class="trip-view-col2">  {{ formatCurrency(trip.price) }}</div>
          </div>

          
          <div class="trip-view-row">
            <!-- {{loggedIn()}}- {{ loggedinUser }} - {{isOwner}} - {{isPublic}} -->
          </div>


          <div v-if="isOwner && trip.status == 6">
               <div class="trip-view-row">
                    <div class="trip-view-col">
                        <div class="default-button" @click="deleteTrip(trip.id)">Rit verwijderen</div>
                    </div>
                  </div>
          </div>


          <GMapMap
            :center="{lat: 51.093048, lng: 6.842120}"
            :zoom="7"
            map-type-id="terrain"
            style="width: 100vw; height: 900px"
          >

          <GMapMarker
            :key="marker.id"
            v-for="marker in markers"
            :position="marker.position"
          />
    
          </GMapMap>


          </div>
    </div>
  </div>

    </transition>

</template>
<script>

import TripService from "../../services/trip-service";

export default {
  name:'settings',
  data() {
    var status = 'loading'
    var trips;
    var markers = [];
    return { trips, status, markers }
  },
  methods: {
    deleteTrip(id) {
      console.log("Delete trip" + id);  
      this.started = 1;
      this.openTrip = id;
      TripService.deleteTrip(id).then(
        () => {
          this.$router.push('/trips/my');      
 
        },
        (error) => {
          console.log("startTrip error: " + error.toString());
        }
      );   
    },
  },
  mounted() {
    var targ = this;
    console.log("this.id: " + this.$route.params.id );
    TripService.getArchivedTrip(this.$route.params.id).then(
      (response) => {
        targ.status = response.status;

        targ.trips = response.data;
        console.log("data loaded from: " + JSON.stringify(targ.trips));
        var originCoords = targ.trips[0]["origin_location"].split(",");
        var origin_marker = {
          id: targ.trips[0]["origin_address"],
          position: {
            lat: parseFloat(originCoords[0]), lng: parseFloat(originCoords[1])
          }
          };

        console.log("origin marker: " + JSON.stringify(origin_marker));

        var destinationCoords = targ.trips[0]["destination_location"].split(",");

          var destination_marker = {
          id: 'destination',
          position: {
             lat: parseFloat(destinationCoords[0]), lng: parseFloat(destinationCoords[1])
          }
          };
          this.markers.push(origin_marker);
          this.markers.push(destination_marker);


      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },

  computed: {

    isPublic() {
      return this.trips[0].public;
    },
    isDriver() {
      var r = false;
      var tripDriver = this.trips[0]["driver"]
      var user = JSON.parse(localStorage.getItem('user')).userid;
      console.log("tripDriver: " + tripDriver + " | userid:" + user);
      if(user == tripDriver) {
        r = true;
      }
      return r;
    },
    isOwner() {
      var r = false;
      var tripOwner = this.trips[0]["owner"]
      var user = JSON.parse(localStorage.getItem('user')).userid;
      console.log("isOwner: " + tripOwner + " | userid:" + user);
      if(user == tripOwner) {
        r = true;
      }
      return r;
    },
  },
  
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,

  }
}
</script>